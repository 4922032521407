<template>
  <InitLayout>
    <b-card
      no-body
      class="d-flex flex-column align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 px-lg-4 login-container-card"
    >
      <!-- login form -->
      <form
        id="login-form"
        class="d-flex flex-column align-items-start justify-content-center px-2 py-3 py-lg-0 px-lg-1 w-100"
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-5"
          no-gutters
        >
          <b-col cols="12" md="10">
            <h3 class="text-left text-main-green font-primary font-weight-bold">
              Password reset
            </h3>
          </b-col>
        </b-row>
        <!-- full row input (Password) -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="password"
              :state="passwordError.status"
              :invalidFeedback="`${passwordError.message}`"
              label="New Password"
              placeholder="Minimum 6 characters"
              isRequired
              :whiteBG="false"
              :type="showPassword ? 'text' : 'password'"
              form="login-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4 rounded-0"
                  variant="transparent"
                  @click="showPassword = !showPassword"
                >
                  <b-icon
                    :icon="showPassword ? 'eye-slash' : 'eye'"
                    aria-label="Help"
                  ></b-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- full row input (Repeat Password) -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="confirmPassword"
              :state="confirmPasswordError.status"
              :invalidFeedback="`${confirmPasswordError.message}`"
              label="Confirm Password"
              placeholder="Minimum 6 characters"
              isRequired
              :whiteBG="false"
              :type="showConfirmPassword ? 'text' : 'password'"
              form="login-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4 rounded-0"
                  variant="transparent"
                  @click="showConfirmPassword = !showConfirmPassword"
                >
                  <b-icon
                    :icon="showConfirmPassword ? 'eye-slash' : 'eye'"
                    aria-label="Help"
                  ></b-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- error status -->
        <b-row
          v-if="pwdResetError.status == true"
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span class="text-danger text-small text-center">{{
              this.pwdResetError.message
            }}</span>
          </b-col>
        </b-row>
        <b-row
          v-if="pwdResetError.status == false"
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span class="text-success text-small text-center">{{
              this.pwdResetError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- form action -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
            cols="12"
            md="10"
          >
            <FormButton
              variant="main-green"
              isBlock
              isPill
              class="font-weight-normal text-main-green mt-4 login-button"
              type="submit"
              :isLoading="isLoader"
              @click.native="pwdResetFn"
              ><span class="text-white">Reset Password</span></FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-card>
  </InitLayout>
</template>

<script>
import InitLayout from "@/layout/InitLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import { ResetPassword } from "@/services/resetPwd.service";

export default {
  name: "Login",
  components: {
    InitLayout,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isLoader: false,
      password: null,
      confirmPassword: null,
      showPassword: false,
      showConfirmPassword: false,
      passwordError: {
        status: null,
        message: null,
      },
      confirmPasswordError: {
        status: null,
        message: null,
      },
      pwdResetError: {
        status: false,
        message: null,
      },
    };
  },
  watch: {
    password(value) {
      this.pwdResetError.status = null;
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
    confirmPassword(value) {
      this.pwdResetError.status = null;
      if (value) {
        if (value != this.password) {
          this.confirmPasswordError.status = false;
          this.confirmPasswordError.message = "Passwords do not match";
        } else {
          this.confirmPasswordError.status = true;
        }
      } else {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message = "Please fill in confirm password";
      }
    },
  },
  methods: {
    async pwdResetFn() {
      if (
        this.passwordError.status &&
        this.confirmPasswordError.status &&
        this.password === this.confirmPassword
      ) {
        this.pwdResetError.status = null;
        const t = this.$route.query.t;
        let payload = {
          password: this.password,
          t,
        };
        this.isLoader = true;
        ResetPassword(payload)
          .then(async (res) => {
            if (res.data) {
              this.pwdResetError.status = false;
              this.pwdResetError.message = res.data.msg;
              setTimeout(() => this.$router.push("/login"), 2000);
            }
          })
          .catch((error) => {
            this.pwdResetError.message = error.response
              ? error.response.data.msg
              : error.message;
            this.pwdResetError.status = true;
          })
          .finally(() => (this.isLoader = false));
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      } else if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      } 
      if (this.confirmPassword !== this.password) {
        this.confirmPasswordError.status = false;
        this.confirmPasswordError.message = "Passwords do not match";
      }
    },
  },
};
</script>

<style scoped>
.login-container-card {
  width: 70%;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
}

.login-container-card .login-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.login-container-card .login-button:hover {
  opacity: 0.9;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
}

.login-container-card .login-google-button {
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.login-container-card .login-google-button:hover {
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
  color: var(--light);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
  }
}
</style>
